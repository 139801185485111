<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="sceneDetails">
            <div slot="header" class="a-fs-16 a-fw-b a-flex-rfsfd">
                <span>订单信息</span>
                <span class="a-mlr-17 a-c-normal a-fs-12" v-if="packageOrderInfo">订单编号：{{ packageOrderInfo.orderSn?packageOrderInfo.orderSn:'-' }}</span>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b">用户信息</div>
            <div class="a-flex-rfsfs a-flex-wrap">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">用户名称</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.name?packageOrderInfo.name:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">手机号</span>
                    <!-- <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.phone?packageOrderInfo.phone:'-' }}</span> -->
                    <span class="content a-c-master">{{ userInfo.dataPermission == 77 ? util.hidePhoneNumber(packageOrderInfo.phone) : packageOrderInfo.phone }}</span>
                </div>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b mt64">支付信息</div>
            <div class="a-flex-rfsfs a-flex-wrap">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付类型</span>
                    <span class="content a-c-master">{{ packageOrderInfo.payChannel | initDic(payChannelDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付来源</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.orderSourceText?packageOrderInfo.orderSourceText:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付金额</span>
                    <span class="content a-c-master">￥{{ util.numFormat(packageOrderInfo.payAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">退款金额</span>
                    <span class="content a-c-master">￥{{ util.numFormat(packageOrderInfo.refundAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">实际费用</span>
                    <span class="content a-c-master">￥{{ util.numFormat(packageOrderInfo.payAmount - packageOrderInfo.refundAmount) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">订单创建人</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.userName?packageOrderInfo.userName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">创建人手机号</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.userPhone?packageOrderInfo.userPhone:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">订单支付人</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.payUserName?packageOrderInfo.payUserName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付人手机号</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.payUserPhone?packageOrderInfo.payUserPhone:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">创建商户</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.placeCompanyName?packageOrderInfo.placeCompanyName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">支付商户</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.payCompanyName?packageOrderInfo.payCompanyName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">退款原因</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.refundMsg?packageOrderInfo.refundMsg:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">赠与原因</span>
                    <span class="content a-c-master">{{ packageOrderInfo&&packageOrderInfo.giveReason?packageOrderInfo.giveReason:'-' }}</span>
                </div>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b mt64" >支付信息明细</div>
            <el-table :border='true' :data="payInfo" style="width: 800px;margin-left:14px;margin-top: 14px">
                <el-table-column label=""  prop='desc'></el-table-column>
                <el-table-column label="支付金额" >
                    <template slot-scope="{ row }">
                        <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.payGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.paySatationPoints || 0) }}</span>
                        <span v-else>￥{{ util.numFormat(row.amount) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退款金额">
                    <template slot-scope="{ row }">
                        <span v-if="row.discountType == 4">通用：￥{{ util.numFormat(row.details.refundGlobalPoints || 0) }}，站点：￥{{ util.numFormat(row.details.refundSatationPoints || 0) }}</span>
                        <span v-else>￥{{ util.numFormat(row.refoundAmount) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="a-c-master a-fs-16 a-fw-b mt64" v-if="activityInfo">活动信息</div>
            <div class="a-flex-rfsfs a-flex-wrap" v-if="activityInfo">
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">活动名称</span>
                    <span class="content a-c-master">{{ activityInfo&&activityInfo.activityName?activityInfo.activityName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">活动类型</span>
                    <span class="content a-c-master">{{ activityInfo.activityRewardsType | initDic(activityRewardsTypeDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">活动规则</span>
                    <span class="content a-c-master">{{ activityInfo.activityType | initDic(activityTypeDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">活动内容</span>
                    <div class="a-flex-rfsc content a-c-master">
                        <span class="">{{ activityInfo.activityRewardsType | initDic(activityRewardsTypeDic) }}：</span>
                        <span v-if="activityInfo.activityRewardsType==0">{{ activityInfo.activityRewardsValue?activityInfo.activityRewardsValue:'-' }}天</span>
                        <span v-if="activityInfo.activityRewardsType==1">{{ activityInfo.activityRewardsValue?Number((activityInfo.activityRewardsValue/100).toFixed(2)):'-' }}元</span>
                        <span v-if="activityInfo.activityRewardsType==2">{{ activityInfo.activityRewardsValue?activityInfo.activityRewardsValue:'-' }}折</span>
                    </div>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">活动说明</span>
                    <span class="content a-c-master">{{ activityInfo&&activityInfo.activityDesc?activityInfo.activityDesc:'-' }}</span>
                </div>
            </div>
            <div class="a-c-master a-fs-16 a-fw-b mt64">套餐信息</div>
            <div class="a-flex-rfsfs mt21 mr80">
                <span class="title c79">关联站点</span>
                <div v-if="(goodsInfo&&goodsInfo.statioinIdList&&goodsInfo.statioinIdList.length)">
                    <span 
                        class="stationTag" 
                        v-for="(item,index) in goodsInfo.statioinIdList" 
                        :key="index">{{ item.name?item.name:'-' }}</span>
                </div>
                <span v-else>-</span>
            </div>
            <div class="a-flex-rfsfs a-flex-wrap" v-if="goodsInfo">
                <!-- <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">套餐生效时间</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.buildName?goodsInfo.buildName:'-' }}</span>
                </div> -->
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">套餐名称</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.name?goodsInfo.name:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80" v-if="packageOrderInfo.payWay != 3">
                    <span class="title c79">有效天数(天)</span>
                    <span 
                        class="content a-c-master"
                        v-if="goodsInfo.type != 5">{{ goodsInfo&&goodsInfo.validity?goodsInfo.validity:'-' }}{{ goodsInfo&&goodsInfo.isTempCard == 1?'小时':'天' }}</span>
                    <span 
                        class="content a-c-master"
                        v-if="goodsInfo.type == 5">{{ goodsInfo&&goodsInfo.validity != -1?goodsInfo.validity+'天':'长期' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">是否系统默认卡</span>
                    <span class="content a-c-master">{{ goodsInfo.isSystemDefaultCard | initDic(isSystemDefaultDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">划线价</span>
                    <span class="content a-c-master">￥{{ util.numFormat(goodsInfo.price) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">实际售价</span>
                    <span class="content a-c-master">￥{{ util.numFormat(goodsInfo.promotionPrice) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">卡片类型</span>
                    <span class="content a-c-master">{{ goodsInfo.type | initDic(cardTypeDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">卡片种类</span>
                    <span class="content a-c-master">{{ goodsInfo.isTempCard | initDic(isTempcardDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">促销描述</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.promotionDescription?goodsInfo.promotionDescription:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">适用范围描述</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.applicableDescription?goodsInfo.applicableDescription:'-' }}</span>
                </div>
                
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">可见模式</span>
                    <span class="content a-c-master">{{ goodsInfo.showMode | initDic(showModelDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">激活模式</span>
                    <span class="content a-c-master">{{ goodsInfo.activationMode | initDic(activeCardDic) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">实体卡费用</span>
                    <span class="content a-c-master">￥{{ util.numFormat(goodsInfo.cardPrice) }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">远程单日开门次数</span>
                    <div class="content a-c-master">
                        <span v-if="goodsInfo.parkTimes==-1">不限制</span>
                        <span v-else>{{ goodsInfo.parkTimes?goodsInfo.parkTimes+'次/日':'-' }}</span>
                    </div>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">单日充电时长(分钟)</span>
                    <span class="content a-c-master" v-if="goodsInfo.parkTimes==-1">不限制</span>
                    <span class="content a-c-master" v-else>{{ goodsInfo&&goodsInfo.quotaDuration?goodsInfo.quotaDuration:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">开售时间</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.enableTimeText?goodsInfo.enableTimeText:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">失效时间</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.expireTimeText?goodsInfo.expireTimeText:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">项目</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.incomeDistriName?goodsInfo.incomeDistriName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">卡组</span>
                    <span class="content a-c-master">{{ goodsInfo&&goodsInfo.cardGroupName?goodsInfo.cardGroupName:'-' }}</span>
                </div>
                <div class="a-flex-rfsfs mt21 mr80">
                    <span class="title c79">关联车辆型号</span>
                    <div v-if="(goodsInfo&&goodsInfo.bikeTypeIdList&&goodsInfo.bikeTypeIdList.length)">
                        <span 
                            class="stationTag" 
                            v-for="(item,index) in goodsInfo.bikeTypeIdList" 
                            :key="index">{{ item.parentName }}-{{ item.name }}</span>
                    </div>
                    <span v-else>-</span>
                </div>
            </div>
            <div style="height: 100px"></div>
        </el-card>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="closePage">&nbsp;&nbsp;&nbsp;关闭&nbsp;&nbsp;&nbsp;</el-button>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex';
import util from '../../utils/util'
export default {
    data() {
        return {
            util: util,
            packageOrderInfo: '',
            goodsInfo: null,
            activityInfo: null,
            id: '',
            cardTypeDic: [],//卡片类型字典
            isTempcardDic: [],//是否临时卡字典
            isSystemDefaultDic: [],//是否系统默认卡字典
            activeCardDic: [],//激活模式字典
            needPostDic: [],//是否邮寄
            showModelDic: [],//可见模式字典
            payChannelDic: [],//支付渠道字典
            activityRewardsTypeDic: [],//活动类型字典
            activityTypeDic: [],//活动规则字典
            payInfo: [],//支付金额
        };
    },
    created () {
        this.$getDic('monthcardType').then(res=>{ this.cardTypeDic = res; })
        this.$getDic('istempcard').then(res=>{ this.isTempcardDic = res; })
        this.$getDic('isSystemDefault').then(res=>{ this.isSystemDefaultDic = res; })
        this.$getDic('activeCard').then(res=>{ this.activeCardDic = res; })
        this.$getDic('needPost').then(res=>{ this.needPostDic = res; })
        this.$getDic('showModel').then(res=>{ this.showModelDic = res; })
        this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        this.$getDic('activityRewardsType').then(res=>{ this.activityRewardsTypeDic = res; })
        this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
        this.id = this.$route.query.id
        if(this.id) this.getOrderInfo()
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.user,
        })
    },
    methods: {
        getOrderInfo () {
            this.$Axios._get({
                url: this.$Config.apiUrl.superOrderDetails,
                method: "get",
                params: {
                    id: this.id
                }
            }).then(res => {
                if(res.result.code == 0){
                    this.packageOrderInfo = res.result.data
                    this.goodsInfo = res.result.data.goodsInfo?JSON.parse(res.result.data.goodsInfo):''
                    this.activityInfo = res.result.data.activityList&&res.result.data.activityList.length?res.result.data.activityList[0]:''

                    // 优惠信息
                    let refoundAmountAll = 0
                    let discountArr = this.packageOrderInfo.orderDiscountInfoList.map(item=>{
                        refoundAmountAll += item.refundPayAmount
                        return {
                            ...item,
                            amount: item.discountPayAmount,
                            refoundAmount: item.refundPayAmount,
                            desc: this.$Config.discountTypeDic[item.discountType],
                            details: item.details ? JSON.parse(item.details) : ''
                        }
                    })
                    refoundAmountAll += this.packageOrderInfo.refundAmount
                    this.payInfo[0] = {
                        amount: this.packageOrderInfo.payAmount,
                        refoundAmount: this.packageOrderInfo.refundAmount,
                        desc: '现金'
                    }
                    this.payInfo = this.payInfo.concat(discountArr)
                }
            })
        },
        closePage () {
            this.$store.dispatch("delView", this.$route);
            this.$router.back()
        }
    },
}
</script>

<style lang="scss" scoped>
.sceneDetails{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
}
.tab-line{
    width: 60px;
    height: 3px;
    border-radius: 1px;
    margin-top: 11px
}
.lineAc{
    background: #007AFF;
    
}
.lineUnAc{
    background: #ffffff;
}
.tabAc{
    color: #303133;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.tabUnAc{
    color: #606366;
    font-size: 12px;
    cursor: pointer;
}
.mb32{
    margin-bottom: 32px
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 21px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 100px;
    margin-right: 16px;
}
.content{
    width: 200px
}
.stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
</style>